<template>
  <div class="avatar-uploader-container">
    <div class="kt-avatar kt-avatar--outline kt-avatar--circle" :class="{'kt-avatar--changed': newUpload}" >
      <div class="kt-avatar__holder" :style="{backgroundImage: `url(${previewUrl ? previewUrl : '/assets/media/misc/loading.svg'})`}"></div>
      <label class="kt-avatar__upload" @click="showUploader" v-b-tooltip.hover title="بۇ جاينى بېسىپ باش سۆرەت ئالماشتۇرۇڭ">
        <i class="fa fa-pen"></i>
      </label>
      <span class="kt-avatar__cancel" @click="resetOldImage" v-b-tooltip.hover title="بۇ جاينى بېسىپ بىكار قىلىڭ">
        <i class="fa fa-times"></i>
      </span>
    </div>
    <crop-uploader
      lang-type="ug"
      :field="field"
      v-model="show"
      :url="server"
      :params="params"
      img-format="jpg"
      :height="height"
      :width="width"
      :headers="headers"
      @crop-upload-success="onUploadSuccess"
    />
  </div>
</template>

<script>
  import cropUploader from "vue-image-crop-upload";
  import { getToken } from "@c@/jwt.service";

  export default {
    name: "AvatarUploader",
    props: {
      field: {
        type: String,
        default: "file"
      },
      imageUrl: String,
      previewUrl: String,
      height: {
        type: Number,
        default: 80
      },
      width: {
        type: Number,
        default: 80
      },
      uploadSuccess: Function,
      uploadForm: {
        type: Object,
        default: ()=> { return {} }
      },
    },
    components: { cropUploader },
    computed: {
      params(){
        return Object.assign({
          name: this.name
        }, this.uploadForm);
      },
    },
    data(){
      return {
        server: `${process.env.VUE_APP_API_URL}/v3/manager/image-upload`,
        show: false,
        headers: {
          Authorization: `Bearer ${ getToken() }`
        },
        oldAvatar: '',
        oldPreview: '',
        newUpload: false
      };
    },
    methods: {
      showUploader(){
        this.show = ! this.show;
      },
      onUploadSuccess(response){
        this.newUpload = true;
        this.oldAvatar = this.imageUrl;
        this.oldPreview = this.previewUrl;
        this.uploadSuccess(response);
      },
      resetOldImage(){
        this.newUpload = false;
        let oldAvatar = this.oldAvatar;
        let oldPreview = this.oldPreview;
        this.$emit('cancel', {oldPreview, oldAvatar });
      },
    }
  }
</script>
